import { T } from '@deity-io/falcon-i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PortalWithState } from 'react-portal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useResponsive from '../../../hooks/useResponsive';
import ProductPromo from '../../UI/ProductPromo/ProductPromo';
import AddToWishlist from './AddToWishlist';
import './ProductGallery.scss';
import Labels from "../Labels/Labels";
import AddToCompare from "../../Compare/AddToCompare";

const ProductGallery = ({ product, data, lifetime_status = 'Std', is_on_order_only = false, days_to_delivery }) => {
  useEffect(() => {
    return () => {
      // make sure html element does not contain the gallery-fullscreen class when the component dismounts
      document.documentElement.classList.remove('gallery-fullscreen');
    };
  }, []);

  const overlay = () => {
    return (
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <Labels type={'product'} slot={'TL'} product={product}/>

        <AddToCompare />
        <AddToWishlist />
      </div>
    );
  };

  const modalClose = (isOpen, closePortal) => {
    function onClick() {
      closePortal();
      if (typeof window !== 'undefined') {
        const rootHtml = document.getElementsByTagName('html')[0];
        if (isOpen === true) {
          rootHtml.classList.remove('gallery-fullscreen');
        }
      }
    }

    return (
      <div className="modal-close-wrapper">
        <button onClick={onClick} type="button" className="close-button">
          &#10005;
        </button>
      </div>
    );
  };

  const modalCloseOnEsc = isOpen => {
    if (typeof window !== 'undefined') {
      const rootHtml = document.getElementsByTagName('html')[0];
      if (isOpen === false) {
        rootHtml.classList.remove('gallery-fullscreen');
      }
    }
  };

  const carousel = (isOpen, openPortal, closePortal) => {
    const popupClassName = isOpen ? 'gallery-pop-up' : '';
    // const [textHover, isHover] = useState(false);

    return (
      <div className={popupClassName}>
        {overlay()}
        {modalClose(isOpen, closePortal)}
        <Carousel
          showArrows
          onChange={() => {
            console.log('change');
          }}
          onClickThumb={() => {
            console.log('thumb');
          }}
          onClickItem={selectedIndex => {
            console.log('item', selectedIndex, isOpen);
            console.log('clicked image', data[selectedIndex].url);
            if (typeof window !== 'undefined') {
              if (isOpen === false) {
                document.documentElement.classList.add('gallery-fullscreen');
              }
            }
            if (isOpen === false) {
              openPortal();
            }
          }}
          renderItem={item => (isOpen ? <>{item}</> : item)}
          infiniteLoop
          interval={2000} // ms
          swipeable
          useKeyboardArrows
          stopOnHover
          showStatus={false}
          showIndicators={false}
          /* TODO: translate below */
          ariaLabel="Product gallery"
        >
          {data &&
            data.map(({ url, label }) => (
              <div key={label}>
                <img src={url} alt={product.name} />
              </div>
            ))}
        </Carousel>
      </div>
    );
  };

  const [hover, setIsHover] = useState(false);
  const { isMobile } = useResponsive();
  return (
    <div className='product-gallery' style={{ position: 'relative' }}>
      <PortalWithState closeOnEsc>
        {/* <PortalWithState closeOnOutsideClick closeOnEsc> */}
        {({ openPortal, closePortal, isOpen, portal }) => (
          <>
            {modalCloseOnEsc(isOpen)}
            {carousel(false, openPortal, closePortal)}
            {portal(carousel(isOpen, openPortal, closePortal))}
          </>
        )}
      </PortalWithState>
      {!!is_on_order_only && days_to_delivery === 0 && (
        <div
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          style={{ position: 'absolute', bottom: '125px', left: 0 }}
        >
          <ProductPromo type="order" promoText={<T id="product.orderInTheStore" />} />
        </div>
      )}
      {hover && !isMobile && (
        <div className="storeOrderHoverTextbox">
          <p className="storeOrderHoverText">
            <T id="product.orderInTheStoreText" />
          </p>
        </div>
      )}
    </div>
  );
};

// TODO: Update
ProductGallery.propTypes = {
  data: PropTypes.array,
};

export default React.memo(ProductGallery);
